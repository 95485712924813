import axios, { AxiosResponse } from "axios";

const speedKickApi =
  process.env.REACT_APP_SPEED_KICK_API_URL || "http://localhost:5000/api";

export interface User {
  emailAddress: string;
  firstName: string;
  lastName: string;
  marketingOptIn: boolean;
}

export interface EntryValue {
  key: string;
  type: string;
  value: number;
}

export interface Entry {
  eventId: string;
  questionId: string;
  userId: string;
  value: EntryValue[];
}

const Api = {
  saveEntry: async (entry: Entry): Promise<any | undefined> => {
    try {
      const entryUrl = `${speedKickApi}/events/${entry.eventId}/entries?version=v2`;
      const response: AxiosResponse = await axios.post(entryUrl, {
        userId: entry.userId,
        entryValue: entry.value,
        questionId: entry.questionId
      }, {
        headers: {
          "X-Api-Key": "Testing",
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  },
  newUser: async (userDetails: User): Promise<{userId: string} | undefined> => {
    try {
      const response: AxiosResponse = await axios.post(`${speedKickApi}/users`, userDetails, {
        headers: {
          "X-Api-Key": "Testing",
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      //Sentry
      return undefined;
    }
  },
};

export default Api;
