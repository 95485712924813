let path = window.location.pathname.split("/")[1];
if (!path) {
    path = "18may";
    if (window.history.replaceState) {
        window.history.replaceState(null, document.title, `/${path}`)
    }
    else {
        window.location.href = `${window.location.origin}/${path}`;
    }
}

export const basepath = path;

const events = {
    "18may": {   
        eventId: "65b8a8d2-f830-4d87-adff-5a0745441533",
        questionId: "43ff0913-a1ac-41d3-82ab-df33c4596e92",
    },
    "game1": {
        eventId: "XX",
        questionId: "XX-X"
    },
    "game2": {
        eventId: "XX",
        questionId: "XX-X"
    },
    "game3": {
        eventId: "XX",
        questionId: "XX-X"
    },
    "game4": {
        eventId: "XX",
        questionId: "XX-X"
    },
    "game5": {
        eventId: "XX",
        questionId: "XX-X"
    },
    "game6": {
        eventId: "XX",
        questionId: "XX-X"
    }
};

export const isValidEvent = (basepath: string): boolean => {
    if (!basepath) {
        return false;
    }

    return events[basepath.toLowerCase()] !== undefined;
}

export const eventDetails = (basepath: string): { eventId: string, questionId: string} => {
    if (!isValidEvent(basepath)) {
        return undefined;
    }
    return events[basepath.toLowerCase()];
}