import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Picker from "rmc-picker/lib/Picker";
import MultiPicker from "rmc-picker/lib/MultiPicker";
import "rmc-picker/assets/index.css";

import { useCreationState } from "../creationFlowHooks";

import Layout from "../shared/Layout";
import Hero from "../shared/Hero";
import Button from "../shared/Button";
import { useSubmitEntry } from "../poster/posterHooks";
import { basepath, eventDetails } from "../common/common";

const FanPick: React.FC = () => {
  const { error, user } = useCreationState();
  const history = useHistory();
  const { loading, submitEntry, submitted } = useSubmitEntry();
  const [pickerValue, setPickerValue] = useState(["0", "0"]);
  const [pageError, setPageError] = useState(undefined);
  React.useEffect(() => {
    if (error) {
      history.replace("/");
      toast.error(error);
    }
    if (pageError) {
      toast.error(pageError.message);
      setTimeout(() => {
        window.location.href = `${window.location.origin}/${basepath}`;
      }, 2000);
    }
  }, [error, history, pageError]);

  const onSubmitEntry = () => {
    const phoenix = Number(pickerValue[0]);
    const victory = Number(pickerValue[1]);
    
    if (Number.isNaN(phoenix) || Number.isNaN(victory)) {
      setPageError({message: "Whoops! Something went wrong here, please try again."});
      return;
    }
    var event = eventDetails(basepath);
    if (event) {
      submitEntry({
        eventId: event.eventId,
        questionId: event.questionId,
        userId: user.id,
        value: [{
          "key": "phoenix",
          "type": "integer-entry",
          "value": phoenix
        },{
          "key": "victory",
          "type": "integer-entry",
          "value": victory
        }]
      });
    } else {
      setPageError({message: "Whoops! Something went wrong here, please try again."});
    }
  }

  React.useEffect(() => {
    if (submitted) {
      history.replace("/success");
    }
  }, [submitted]);

  const onChange = (value) => {
    setPickerValue(value);
  }

  const items: any[] = [];
    for (let i = 0; i <= 20; i++) {
      items.push(<Picker.Item value={i + ''} key={i}>
        {i}
      </Picker.Item>);      
    }
    const items2: any[] = [];
    for (let i = 0; i <= 20; i++) {
      items2.push(<Picker.Item value={i + ''} key={i}>
        {i}
      </Picker.Item>);      
    }

  return (
    <Layout>
      <Hero role="FANPICK" />
      <div className="flex flex-col flex-1 items-center mx-5 overflow-hidden h-full">
        <div className="mt-7">
          <h1 className="text-sm font-bold text-center">
            What do you think the score will be between
          </h1>
          <h1 className="text-sm font-bold text-center">
            Wellington Phoenix & Melbourne Victory
          </h1>
        </div>
        <div className="flex flex-grow pb-4 mt-4 w-full rounded-lg">
          <div className="place-items-center flex flex-1 flex-col rounded-lg px-5 pt-7 pb-7 mb-2 bg-gray-50 overflow-y-auto">
            <h1 className="text-sm font-bold text-center pb-7">
              Enter Score
            </h1>
            {/* <div className="grid grid-cols-2 gap-4"> */}
            <div className="flex w-64">
              <div className="mr-4 text-center w-32">Wellington Phoenix</div>
              <div className="ml-4 text-center w-32">Melbourne Victory</div>
            </div>
            <MultiPicker
              className="w-64"
              selectedValue={pickerValue}
              onValueChange={onChange}>
              <Picker>
                {items}
              </Picker>

              <Picker>
                {items2}
              </Picker>
            </MultiPicker>
          </div>
        </div>
      </div>
      <div className="flex items-end justify-center py-2 bg-black">
        <div className="flex">
        <Button
          theme="secondary"
          className="self-end"
          onClick={onSubmitEntry}
          isLoading={loading}
        >
          NEXT
        </Button>
        </div>
      </div>
    </Layout>
  );
};

export default FanPick;
