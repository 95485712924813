import * as React from "react";

import header from "./oppo.page.header.png";

interface Props {
  role?: "SPLASH" | "DETAILS" | "FANPICK" | "ENTER" | "SUCSSES";
  defaultHeader?: JSX.Element;
}

const defaultHeroHeader = (
  <div className="flex flex-col items-center bg-bgBody justify-center">
    <img src={header} />
  </div>
);

const Hero: React.FC<Props> = ({
  role,
  defaultHeader = defaultHeroHeader,
}: Props) => {
  console.log(role);
  return defaultHeader;
};

export default Hero;
