import * as React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import Markdown from "react-markdown";

import Button from "../shared/Button";
import Hero from "../shared/Hero";
import { termsText } from "./terms";
import header from "./oppo.pick.the.score.png";

interface Props {
  children: string;
  className: string;
}

export const AppLayout: React.FC<Props> = ({ children, className }: Props) => {
  return (
    <div
      className={classNames(
        "flex flex-col flex-1 overflow-hidden relative",
        className
      )}
    >
      {children[0]}
      <div className="pb-3 my-3 overflow-y-auto">
        <div className="flex flex-col items-center mx-8 pt-8">
          <img src={header} />
        </div>
        <div className="flex flex-1 flex-col items-center mx-8">
          {children[1]}
        </div>
        <div className="flex flex-col justify-end pt-5">
          {children[2]}
        </div>
        <div className="flex flex-col justify-end pt-5 pb-5">
          {children[3]}
        </div>
      </div>
    </div>
  );
};

const Splash: React.FC = () => {
  const history = useHistory();
  const start = () => {
    history.push("/customer-details");
  };
  const [termsModalOpen, setTermsModalOpen] = React.useState(false);
  const onOpenTermsModal = () => setTermsModalOpen(true);
  const onCloseTermsModal = () => setTermsModalOpen(false);

  return (
    <AppLayout className="bg-white">
      <Hero role="SPLASH" />
      
      <div className="pb-1 my-2 overflow-y-auto">
      <Modal
          open={termsModalOpen}
          onClose={onCloseTermsModal}
          center
          styles={{
            modal: {
              height: "unset",
              minHeight: "20rem",
              width: "80%",
            },
          }}
          classNames={{ modal:"!bg-white"}}
        >
          <div className="p-6 antialiased font-body bg-white">
            <Markdown>{termsText}</Markdown>
          </div>
        </Modal>
      </div>
      <div className="flex items-end mx-4 pb-5">
        <Button theme="primary" className="" onClick={start}>
          START
        </Button>
      </div>
      <div className="flex flex-col items-center mx-8 overflow-hidden">
        <h1 className="text-xs font-bold text-center pb-3">
          By pushing start you agree to the terms and conditions of this promotion
        </h1>
        <h1 className="text-xs font-bold text-center">
          Click here to view <button
            className="text-xs font-bold underline"
            onClick={onOpenTermsModal}
            type="button"
          >
            terms and conditions
          </button>
        </h1>
      </div>
      

    </AppLayout>
  );
};

export default Splash;
